import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import MenuToggler from './MenuToggler';
import HoverLink from './HoverLink';
import Cardio from '../content/Cardio'; // import the component that should be rendered
import cat from '../images/cat.jpeg';

const Menu = () => {
  const location = useLocation();
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 700);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 700);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let menuClass = isLargeScreen && location.pathname !== '/' ? 'menu menu-fixed-lg col-6' : 'menu col-6';

  // If on large screen and on the homepage, add the 'only-on-large-home' class
  if (isLargeScreen && location.pathname === '/') {
    menuClass += ' only-on-large-home';
  }

  // If on small screen and on the homepage, add the 'menu-only-small-home' class
  if (!isLargeScreen && location.pathname === '/') {
    menuClass = 'menu menu-only-small-home';
  }

  // If on small screen and not on the homepage, add the 'hide-on-small' class
  if (!isLargeScreen && location.pathname !== '/') {
    menuClass += ' hide-on-small';
  }

  return (
    <div className={menuClass}>
      <div>
        <MenuToggler />
        <div className="d-flex flex-column">
          <HoverLink destination="/cardio" title="Visualizing Cardiology Data" subclient="General Electric" imagethumb={cat} />
          <HoverLink destination="/tanzuplatform" title="Combined Infrastructure Visibility with Tanzu Platform" subclient="Tanzu" imagethumb={cat}/>
          <HoverLink destination="/devportal" title="Tools for Developer Productivity" subclient="VMWare" imagethumb={cat}/>
          <HoverLink destination="/devportalaccess" title="Cloud Foundry & Backstage Integration" subclient="Tanzu" imagethumb={cat}/>
         <HoverLink destination="/ai" title="VMware AI Tooling" subclient="VMWare" imagethumb={cat}/>
          <HoverLink destination="/mcd" title="McDonalds Kiosk Prototyping & Motion Design" subclient="McDonalds" imagethumb={cat}/>
          <HoverLink destination="/rcm" title="Redefining Contextual Menus" subclient="General Electric" imagethumb={cat}/>
          <HoverLink destination="/voice" title="Voice Powered Modality Research" subclient="General Electric" imagethumb={cat}/>
          <HoverLink destination="/motion" title="Motion Engineering for Edison Design System" subclient="General Electric" imagethumb={cat}/>
          <HoverLink destination="/scrolling" title="Z-Index Scrolling for Radiology Patent" subclient="General Electric" imagethumb={cat}/>
          {/* <HoverLink destination="/search" title="Search Component Design & Delivery" subclient="General Electric" imagethumb={cat}/> */}
          <HoverLink destination="/comcast" title="Maintaing Colorful Screens with Comcast" subclient="Comcast" imagethumb={cat}/>
          <HoverLink destination="/goldman" title="Charting Consumer Savings with Goldman Sachs" subclient="Goldman Sachs" imagethumb={cat}/>
          <HoverLink destination="https://old-stuff-1cb1065cbd37.herokuapp.com/index.php" title="Archives" subclient="Blasts from the past" imagethumb={cat}/>

        </div>
      </div> 
    </div>
  )
}

export default Menu