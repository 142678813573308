import React, { useState, useEffect } from "react";

import getContent from '../components/getContent.js';
import ProblemContainer from "../components/ProblemContainer.js";
import ProjectHero from "../components/ProjectHero.js";
import ProjectSetup from "../components/ProjectSetup.js";
import Block from "../components/Block.js";
import NextBack from "../components/NextBack.js";
import Image from "../components/Image.js";
import InsightsBlock from "../components/InsightsBlock.js";
import SectionTitle from "../components/SectionTitle.js";
import DetailLayout from "../components/Detaillayout";
import api from "../images/assets/devaccess/api.jpg"; // Example of adding assets
import issue from "../images/assets/devaccess/issue.jpg"; // Example of adding assets
import problemillustration from "../images/assets/devaccess/problemillustration.jpg"; // Example of adding 
import roles from "../images/assets/devaccess/roles.jpg"; // Example of adding assets
import workflow from "../images/assets/devaccess/workflow.jpg"; // Example of adding assets



const DevPortalAccess = () => {
    return (
      <DetailLayout>
        <div className="body-container">
        
        <ProjectHero
        client="Cloud Foundry & Backstage Integration"
        project="Streamlining Role Synchronization Between Cloud Foundry and Backstage"
        summary={["This project aimed to bridge the gap between the roles and access control in Cloud Foundry and Backstage, ensuring seamless synchronization for developers and admins managing app deployments. The solution provides clarity and consistency in user permissions across both platforms, helping to reduce confusion and improve collaboration between teams."]}
        timeline="2 months"
        />
        
        <SectionTitle title="Project Snapshot" />

        <ProjectSetup
          problem="Resolve role synchronization challenges across Cloud Foundry and Backstage, ensuring consistent user access and permissions."
          solution="Developed a custom API that synchronizes user roles between Cloud Foundry and Backstage, ensuring that developers and admins can seamlessly manage applications and deployments."
          outcome="Our solution eliminated role mismatches and conflicts between Cloud Foundry and Backstage, enhancing team collaboration and workflow efficiency. The new API also provided clearer role management, increasing user satisfaction and reducing onboarding time."
          />

        <SectionTitle title="Process" />
        <Block subhead="Identifying the Problem" subtext="The need for synchronizing roles between Cloud Foundry and Backstage arose from mismatched permissions that caused deployment failures and restricted access for users. The challenge was to create an API that would map and update roles between the two platforms, ensuring consistency."  />

        <Image image={problemillustration} />
        
        <Block subhead="Workflow/Experience Mapping" />

        <Image image={workflow} />
      
        <Block subhead="Research and Planning" subtext="We started by analyzing the RBAC (Role-Based Access Control) systems in both Cloud Foundry and Backstage to understand how roles were being assigned and what mismatches were occurring. Interviews with developers and admins revealed the critical pain points in accessing and managing roles." />
        <Image image={roles} />


        <Block subhead="Designing the API Solution" subtext="A custom syncing API was developed to map Cloud Foundry roles (e.g., developer, operator) to their counterparts in Backstage. The API was designed to ensure that roles were always aligned, with conflict resolution and logging mechanisms to handle errors and provide feedback."  />
        <Image image={issue} />


        <Block subhead="Handling UX Success and Error States" subtext="We incorporated detailed success and error feedback to ensure smooth user experience during role synchronization. Successful actions displayed confirmation messages, while errors were clearly explained with actionable steps for resolution."  />

        <Image image={api} />
      
      </div>
    </DetailLayout>
 
    )
  }


export default DevPortalAccess;