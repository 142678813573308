import React, { useState, useEffect } from "react";
import ProjectSetup from "../components/ProjectSetup.js";
import Block from "../components/Block.js";
import SectionTitle from "../components/SectionTitle.js";
import Image from "../components/Image.js";
import Detaillayout from "../components/Detaillayout";
import arch from "../images/assets/tanzuplatform/1.Arch.jpg";
import concept from "../images/assets/tanzuplatform/2.b-mainflow.jpg";
import userflow from "../images/assets/tanzuplatform/2.UserFlow.jpg";
import design from "../images/assets/tanzuplatform/3.Design.jpg";
import perms from "../images/assets/tanzuplatform/4.PermissionsForConfig.jpg";
import dashboard from "../images/assets/tanzuplatform/5.DashboardHookup.jpg";
import summary from "../images/assets/tanzuplatform/6.Summary.jpg";
import stepper from "../images/assets/tanzuplatform/7stepper.jpg";
import install from "../images/assets/tanzuplatform/8.Install.jpg";



import ProjectHero from "../components/ProjectHero.js";

const TanzuPlatform = () => {
    return (
      <Detaillayout>
        <div className="body-container">
        
        <ProjectHero
        client="Tanzu by Broadcom"
        project="Combined Infrastructure Visibility with Tanzu Platform"
        summary={["The Tanzu Platform provides a unified view for both Tanzu and Cloud Foundry customers to manage their infrastructure and applications in one place. Cloud Foundry users, who lacked a collective view of their foundations, were especially limited. By integrating Cloud Foundry into Tanzu, we enabled users to manage apps across both Kubernetes and VMs. Our task was to create a seamless onboarding flow for new Cloud Foundry foundations, giving customers a centralized space to view their infrastructure on the Tanzu Platform."]}
        timeline="2 months"
        />
        
        <SectionTitle title="Project Snapshot" />

        <ProjectSetup
          problem="Customers were struggling to manage their applications across both Kubernetes and VMs, with Cloud Foundry users lacking a collective view of their foundations."
          solution="An onboarding flow that seamlessly integrates Cloud Foundry into Tanzu, providing customers with a centralized space to view their infrastructure."
          outcome="Platform Engineers and Application Developers can now manage their applications across both Kubernetes and VMs in one place."
          />

        <SectionTitle title="Project Summary" />
        
        <Block subhead="Tanzu Platform Overview" subtext="I started by working closely with engineering and product management to understand how Tanzu Platform worked. Our main goal was to bring Cloud Foundry data into Tanzu and give users the tools they needed to integrate it back into Cloud Foundry. This helped shape the platform’s design and user experience."  />

        <Image image={concept} />

        <Block subhead="Architecture & Permissions" subtext="I started by working closely with engineering and product management to understand how Tanzu Platform injested Cloud Foundry Data."  />

        <Image image={arch} />
      
        <Block subhead="User Flow in Tanzu Platform" subtext="I then mapped the user flow, outlining the steps for both Platform Engineers and App Developers, and identifying the parts of the system they would interact with along with what type of permission they needed. By aligning the user flow with the permissions, I was able to highlight areas that needed user access to engineering."
         />

        <Image image={userflow} />

        <Block subhead="Plugin Management" subtext="Additionally, in order for users to complete onboarding, they needed the platform to generate keys to enter into their Cloud Foundry systems. We needed to also map out the permissions on the Cloud Foundry side in order to give a complete picture of user access throughout the onboarding flow."  />
        <Image image={summary} />
        <Image image={perms} />

        <Block subhead="Building the Design" subtext="After mapping out the narrative and understanding the permissions needed throughout all of the layers, I started designing how the onboarding flow would work."  />

        <Image image={stepper} />
        <Image image={install} />
          

        <Block subhead="Foundation Dashboard" subtext="After the completed process, users are able to view their connected foundations inside of the Tanzu Platform"  />
   
        <Image image={dashboard} />    
      
      </div>
    </Detaillayout>
    )
}

export default TanzuPlatform;