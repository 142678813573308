import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

//Components
import { AuthProvider } from './components/AuthContext';
import ProjectNavigation from "./components/ProjectNavigation";
import Menu from './components/Menu';
import PrivateRouteWrapper from './components/PrivateRouteWrapper';
import DemoTitle from './components/DemoTitle';
import Navibar from './components/Navibar';
import Footer from './components/Footer';

import ReactGA from "react-ga4";

//content
import About from './content/About';
import Cardio from './content/Cardio';
import VoiceUX from './content/VoiceUX';
import Comcast from './content/Comcast';
import Goldman from './content/Goldman';
import MCD from './content/MCD';
import Motion from './content/MotionLib';
import RCM from './content/RightClick';
import Scrolling from './content/Scrolling';
import TanzuPlatform from './content/TanzuPlatform';
import DevPortalAccess from './content/DevPortalAccess';


// import Search from './content/Search';
import DevPortal from './content/Devportal';
import AI from './content/AIcopilot';
import BackToTop from "./components/BackToTop"; // import the new component


const ConditionalNavibar = () => {
  const location = useLocation();
  return location.pathname !== "/" ? <Navibar bg="light" expand="lg"/> : null;
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRouter = () => {


  // Initialize Google Analytics
  ReactGA.initialize("G-HYNBGMJ9PD");

  const [isMenuLarge, setIsMenuLarge] = useState(true);


  return (
    <>
    <div className="container-layout pt-sm-2 pt-lg-5"> 
        <AuthProvider>
        <Router>
        <BackToTop />
        <ScrollToTop />
        <ConditionalNavibar />
        <PageViewReporter />
          <div className="flex-md-row d-md-block">
            <Routes>
                <Route path="/cardio" element={<PrivateRouteWrapper element={<Cardio />} />} />
                <Route path="/comcast" element={<PrivateRouteWrapper element={<Comcast />} />} />
                <Route path="/goldman" element={<PrivateRouteWrapper element={<Goldman />} />} />
                <Route path="/mcd" element={<PrivateRouteWrapper element={<MCD />} />} />
                <Route path="/motion" element={<PrivateRouteWrapper element={<Motion />} />} />
                <Route path="/rcm" element={<PrivateRouteWrapper element={<RCM />} />} />
                <Route path="/scrolling" element={<PrivateRouteWrapper element={<Scrolling />} />} />
                {/* <Route path="/search" element={<PrivateRouteWrapper element={<Search />} />} /> */}
                <Route path="/voice" element={<PrivateRouteWrapper element={<VoiceUX />} />} />
                <Route path="/devportal" element={<PrivateRouteWrapper element={<DevPortal />} />} />
                <Route path="/devportalaccess" element={<PrivateRouteWrapper element={<DevPortalAccess />} />} />
                <Route path="/tanzuplatform" element={<PrivateRouteWrapper element={<TanzuPlatform />} />} />
                <Route path="/ai" element={<PrivateRouteWrapper element={<AI />} />} />

                <Route exact path="/" element={<DemoTitle />} />
            </Routes>
            <Menu isLarge={isMenuLarge} setIsLarge={setIsMenuLarge} />
          </div>
          <div className="flex-row">
            <Footer />
          </div>
        </Router>
        </AuthProvider>
    </div>
  </>
  );
}

function PageViewReporter() {
  const location = useLocation();

  useEffect(() => {
    // To Report page view
    ReactGA.send(window.location.pathname + window.location.search);
  }, [location]);

  return null;
}


export default AppRouter;